import revive_payload_client_4sVQNw7RlN from "/vercel/path1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path1/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_eTVJQYlCmx from "/vercel/path1/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import plugin_Jozdw60ZsE from "/vercel/path1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import apollo_setup_Sql2fyttl4 from "/vercel/path1/plugins/apollo-setup.js";
import app_lock_SXlmGP6RrF from "/vercel/path1/plugins/app-lock.js";
import auth0_mcHDij63ib from "/vercel/path1/plugins/auth0.js";
import bugsnag_6kAVje3qV5 from "/vercel/path1/plugins/bugsnag.js";
import documents_api_OS47iCY9yF from "/vercel/path1/plugins/documents-api.js";
import feature_flags_3X7A4FWWIa from "/vercel/path1/plugins/feature-flags.ts";
import formatCurrency_4n0Fp8ZRvm from "/vercel/path1/plugins/formatCurrency.js";
import formatDate_p1OBxYH5Hk from "/vercel/path1/plugins/formatDate.js";
import kudo_id_40TbkJMUbk from "/vercel/path1/plugins/kudo-id.js";
import kudo_masks_ocMZR62kb6 from "/vercel/path1/plugins/kudo-masks.ts";
import kudo_toaster_RMnRLc5nH0 from "/vercel/path1/plugins/kudo-toaster.js";
import segment_FjY7HTAKHp from "/vercel/path1/plugins/segment.js";
import temp_portal_plugin_SVP4SD19p5 from "/vercel/path1/plugins/temp-portal-plugin.js";
import urql_client_Gjeex2RKZY from "/vercel/path1/plugins/urql-client.js";
import vuelidateFilters_uHiXVCMLGV from "/vercel/path1/plugins/vuelidateFilters.js";
import vuex_store_TlPWERRJFc from "/vercel/path1/plugins/vuex-store.js";
import zendeskChat_znXxfeDryu from "/vercel/path1/plugins/zendeskChat.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_eTVJQYlCmx,
  plugin_Jozdw60ZsE,
  apollo_setup_Sql2fyttl4,
  app_lock_SXlmGP6RrF,
  auth0_mcHDij63ib,
  bugsnag_6kAVje3qV5,
  documents_api_OS47iCY9yF,
  feature_flags_3X7A4FWWIa,
  formatCurrency_4n0Fp8ZRvm,
  formatDate_p1OBxYH5Hk,
  kudo_id_40TbkJMUbk,
  kudo_masks_ocMZR62kb6,
  kudo_toaster_RMnRLc5nH0,
  segment_FjY7HTAKHp,
  temp_portal_plugin_SVP4SD19p5,
  urql_client_Gjeex2RKZY,
  vuelidateFilters_uHiXVCMLGV,
  vuex_store_TlPWERRJFc,
  zendeskChat_znXxfeDryu
]