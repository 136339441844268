export default defineNuxtPlugin(() => {
  return {
    provide: {
      formatCurrency: (value, format) => {
        const currency = format === 'dollars' ? value : value / 100

        const formattedCurrency = currency.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

        return format === 'dollars' ? formattedCurrency.split('.')[0] : formattedCurrency
      },
    },
  }
})
