/**
 * This should be used for the pagination of all dashboard pages to define the
 * valid number of items to display at once.
 * @type {[{label: number, value: number}]}
 */
export const dashboardPerPageOptions = [
  {
    label: 5,
    value: 5,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 15,
    value: 15,
  },
  {
    label: 20,
    value: 20,
  },
]

/**
 * This function calculates a valid offset and limit to match a valid option.
 * It will also force the offset to be a strict multiple of the limit. This ensures
 * that there will never be a time were the offset and limit are out of sync
 * with the Typesense method of working with `page` and `per page`.
 *
 * @param offset {number | string} The number of items to skip
 * @param limit {number | string} The number of items to display
 * @param perPageOptions {[{label: number, value: number}]} The list of valid options for the `limit`
 * @returns {{offset: number, limit: number, updated: boolean}} An object
 * containing the new `offset`, `limit`, and `updated` if they are different from the
 * original offset and limit that were passed in.
 */
export function calculateValidOffsetLimit(offset, limit, perPageOptions) {
  // We explicitly cast to number here to account for when the offset and limit
  // come from the route as a string
  limit = Number(limit) || 10
  offset = Number(offset) || 0

  let newOffset = offset
  let newLimit = limit
  const validLimit = perPageOptions.findIndex(obj => obj.value === newLimit) !== -1

  if (!validLimit) {
    for (const { value } of perPageOptions.slice().reverse()) {
      if (value < limit) {
        newLimit = value
        break
      }
    }
  }

  if (offset % limit !== 0) {
    newOffset = Math.floor(offset / limit) * limit
  }

  return {
    limit: newLimit,
    offset: newOffset,
    updated: limit !== newLimit || offset !== newOffset,
  }
}
