import validate from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/vercel/path1/middleware/auth.global.js";
import redirect_45claim_45global from "/vercel/path1/middleware/redirect-claim.global.js";
import manifest_45route_45rule from "/vercel/path1/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  redirect_45claim_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}