import type { Client } from '@bugsnag/js'
import * as LDClient from 'launchdarkly-js-client-sdk'

type ReactiveFlag = Ref<any>

// FFUser is an interface that describes that information needed to create a user context for LaunchDarkly
interface FFUser {
  id: string
  firstName: string
  lastName: string
  email: string
  roles: string[]
  permissions: string[]
}

// OpenlyUserContext is an interface that contains most of the same information as FFUser but it is formatted for LaunchDarkly.
// The `id` from FFUser is used as the `key` in OpenlyUserContext and that is why OpenlyUserContext extends FFUser with `id` omitted.
interface OpenlyUserContext extends Omit<FFUser, 'id'> {
  kind: 'user'
  key: string
}

// FlagMap describes a map that associates a flag key from LaunchDarkly to refs containing the value of that flag.
// The object contains an editable version of the ref to update the flag's value on the `change` event from an LDClient.
// The object contains a read only version of the ref to return the same read only ref each time `useFlag()` is called.
type FlagMap = {
  [key: string]: {
    ref: ReactiveFlag,
    readonlyRef: Readonly<ReactiveFlag>
  }
}

function userToContext(user: FFUser): OpenlyUserContext {
  return {
    kind: 'user',
    key: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    roles: user.roles,
    permissions: user.permissions,
  }
}

// initClient is a function that takes an OpenlyUserContext and a LaunchDarkly Client ID.
// This function creates a new client and waits for it to initialize before returning the new client.
async function initClient(context: OpenlyUserContext, launchDarklyClientID: string) {
  if (!launchDarklyClientID) {
    throw new Error('Feature Flag Nuxt Plugin: LaunchDarkly Client ID is missing.')
  }

  const newClient = LDClient.initialize(
    launchDarklyClientID,
    context
  )

  await newClient.waitForInitialization()

  return newClient
}

// addFlagToMap is a function that takes a string for a flag key, a value for the flag, and a FlagMap to add the flag to.
// Flags are added to the map as an object containing a ref and a read only version of that ref.
// This allows the same read only ref to be shared with all requestors instead of creating a new read only ref each time flag is requested.
function addFlagToMap(flag: string, value: any, flagMap: FlagMap) {
  const reactiveFlag: ReactiveFlag = ref(value)

  if (flagMap[flag]) {
    throw new Error(`Feature Flag Nuxt Plugin: flag ${flag} is already in the FlagMap and can not be added again.`)
  }

  flagMap[flag] = {
    ref: reactiveFlag,
    readonlyRef: shallowReadonly(reactiveFlag),
  }
}

// initFlagMap is a function that takes an LDClient.
// This function creates a new FlagMap and populates it with the flag values from the provided client.
// This function then returns that FlagMap.
function initFlagMap(client: LDClient.LDClient) {
  const allFlag = client.allFlags()

  const newFlagMap: FlagMap = {}

  for (const [flag, value] of Object.entries(allFlag)) {
    addFlagToMap(flag, value, newFlagMap)
  }

  return newFlagMap
}

// addFlagChangeHandler is a function that takes an LDClient and a FlagMap.
// This function adds a handler function for the provided LDClient's `change` event.
// When called this handler function will iterate over the changes passed to it and update the provided FlagMap accordingly.
// This function returns a closure that removes the handler function for the `change` event from the provided client.
function addFlagChangeHandler(client: LDClient.LDClient, flagMap: FlagMap) {
  const handler = (flagChanges: LDClient.LDFlagChangeset) => {
    for (const [flag, value] of Object.entries(flagChanges)) {
      flagMap[flag].ref.value = value.current
    }
  }

  client.on('change', handler)

  const removeFlagChangeHandler = () => {
    client.off('change', handler)
  }

  return removeFlagChangeHandler
}

export default defineNuxtPlugin((nuxtApp) => {
  const $bugsnag: Client = nuxtApp.$bugsnag as Client
  const { public: config } = useRuntimeConfig()

  let client: LDClient.LDClient | null
  let flagMap: FlagMap | null
  let removeFlagChangeHandler: (() => void) | null

  // setUser is a function that takes an FFUser object to create a context for the user.
  // This function will create a new LaunchDarkly client if one does not exist, or it will update the user context of the existing client.
  async function setUser(user: FFUser) {
    try {
      const userContext = userToContext(user)
      // If there is not client then create one.
      // Otherwise, update the existing client with the updated user context.
      if (!client) {
        client = await initClient(userContext, config.launchDarklyClientID as string)
        flagMap = initFlagMap(client)
        removeFlagChangeHandler = addFlagChangeHandler(client, flagMap)
      } else {
        await client.identify(userContext)
      }
    } catch (error: any) {
      $bugsnag.notify(error)
    }
  }

  // closeClient is a function that will tear down the current client.
  // This function will also remove the listener for the clients change events and clear the `client` and `flagMap` variables for the plugin.
  async function closeClient() {
    removeFlagChangeHandler?.()
    removeFlagChangeHandler = null

    if (!client) {
      flagMap = null
      return
    }

    await client.close()

    client = null
    flagMap = null
  }

  // useFlag is a function that takes a string that is the key for the desired flag.
  // This function will check if the key exists in the map.
  // If the key is not in the current map then it will be added with a default value of `null`.
  //   - Flags added this way will still be updated if the flag is later updated by the LDClient `change` handler.
  // A read only ref to the flag's value will be returned.
  // This read only ref will allow consumers of the flag to be reactively updated if that flag's value changes.
  // Because the ref is read only consumers of the flag will not be able to change the flags value from outside of the plugin.
  function useFlag(flag: string) {
    if (!flagMap) {
      throw new Error('Feature Flag Nuxt Plugin: `flagMap` is not initialized.')
    }

    if (!flagMap[flag]) {
      addFlagToMap(flag, null, flagMap)
    }

    return flagMap[flag].readonlyRef
  }

  return {
    provide: {
      featureFlags: {
        setUser,
        closeClient,
        useFlag,
      },
    },
  }
})
