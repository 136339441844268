import { createStore, createLogger } from 'vuex'
import { state as SelfState, mutations as SelfMutations, actions as SelfActions } from '~/store/self'
import { state as ErrorState, mutations as ErrorMutations } from '~/store/error'
import ClaimState from '~/store/claim//state'
import ClaimGetters from '~/store/claim/getters'
import ClaimMutations from '~/store/claim/mutations'
import ClaimActions from '~/store/claim/actions'

const self = {
  namespaced: true,
  state: SelfState(),
  mutations: SelfMutations,
  actions: SelfActions,
}

const error = {
  namespaced: true,
  state: ErrorState(),
  mutations: ErrorMutations,
}

const claim = {
  namespaced: true,
  state: ClaimState(),
  getters: ClaimGetters,
  mutations: ClaimMutations,
  actions: ClaimActions,
}

const store = createStore({
  modules: {
    self,
    error,
    claim,
  },
  // see docs for createLogger: https://vuex.vuejs.org/guide/plugins.html#built-in-logger-plugin
  plugins: process.env.NODE_ENV === 'development'
    ? [createLogger()]
    : [],
})

export default defineNuxtPlugin(() => {
  return {
    provide: {
      store,
    },
  }
})
