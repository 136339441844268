import hasValidAccessToken from '~/middleware/auth-utils/hasValidAccessToken'
import getAccessToken from '~/middleware/auth-utils/getAccessToken'
import saveDestinationURL from '~/middleware/auth-utils/saveDestinationURL'
import redirectToDestinationURL from '~/middleware/auth-utils/redirectToDestinationURL'
import silentlyAuthenticateWithAuth0 from '~/middleware/auth-utils/silentlyAuthenticateWithAuth0'

export default defineNuxtRouteMiddleware(async(to, _from) => {
  const {
    $auth,
    $bugsnag,
    $store,
  } = useNuxtApp()

  const router = useRouter()

  try {
    const { getToken, onLogin } = useApollo()
    const accessToken = await getToken()

    if (hasValidAccessToken(accessToken)) {
      await $store.dispatch('self/requestSelf')
      return
    }

    // This determines if the user entered the app from the Auth0 login page by checking if the current route contains the Auth0 hash
    if (to?.hash) {
      const accessToken = await getAccessToken($auth)

      // Sets an apollo-token cookie containing the Auth0 access token that is used for authentication
      await onLogin(accessToken)

      redirectToDestinationURL(router)

      return
    }

    const silentAccessToken = await silentlyAuthenticateWithAuth0($auth, $bugsnag)

    if (silentAccessToken) {
      // Sets an apollo-token cookie containing the Auth0 access token that is used for authentication
      await onLogin(silentAccessToken)

      await $store.dispatch('self/requestSelf')
      return
    }

    saveDestinationURL(to)

    // Send user to Auth0 login page
    $auth.authorize({})
  } catch (error) {
    $bugsnag.notify(error)

    const { public: config } = useRuntimeConfig()

    location.replace(`${config.legacyPortalDomain}/agents/dashboard`)
  }
})
