/**
 * Evaluates provided route guards and handles redirecting if redirect is provided.
 * Manages error handling and fallback scenarios for route guarding.
 * `routeGuards` - Function or array of functions that can be asynchronous.
 * Once a route guard fails, evaluation stops and the provided redirect is evaluated.
 * `redirectPath` - Optional parameter that can be a string
 * or a function that returns a string. If provided, this string will be used for redirecting.
 * If a redirect value is not provided, route guard will simply prevent routing.
 */
export default function(routeGuards, redirectPath) {
  return defineNuxtRouteMiddleware(async(to, from) => {
    const { public: config } = useRuntimeConfig()

    const nuxtApp = useNuxtApp()

    if (!Array.isArray(routeGuards)) {
      routeGuards = [routeGuards]
    }

    for (const guard of routeGuards) {
      let guardPassed

      try {
        guardPassed = await guard(nuxtApp)
      } catch (error) {
        nuxtApp.$bugsnag.notify(error)
        location.replace(`${config.legacyPortalDomain}/agents/dashboard`)
      }

      if (guardPassed) {
        continue
      }

      if (typeof redirectPath === 'function') {
        redirectPath = await redirectPath(nuxtApp)
      }

      if (redirectPath) {
        return navigateTo(redirectPath)
      }

      if (to.path === from.path) {
        // This occurs when a user enters the application on a route that is guarded from them.
        // If we have no `redirectPath` to redirect the user to, we will send them to the policy search screen in legacy.
        // We first redirect the user to `/ping` because it is a page with no content.
        // This prevents us from showing the user a partially broken screen during the redirect to legacy.
        await navigateTo('/ping')
        window.location = `${config.legacyPortalDomain}/agents/dashboard`
        return
      }

      return abortNavigation()
    }
  })
}
