import KudoToaster from '@openly-engineering/kudo/plugins/kudo-toaster'

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(KudoToaster)

  return {
    provide: {
      kudoToaster: vueApp.config.globalProperties.$kudoToaster,
    },
  }
})
