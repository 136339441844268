import type { MaskOptions } from 'maska'

export default defineNuxtPlugin(() => {
  type KudoMaskOptions = Pick<MaskOptions, 'mask' | 'tokens'>

  const date: KudoMaskOptions = {
    mask: 'M#/D#/Y###',
    tokens: {
      // Leading digit for months
      M: { pattern: /[0-1]/ },
      // Leading digit for days
      D: { pattern: /[0-3]/ },
      // Leading digit for years
      Y: { pattern: /[1-2]/ },
    },
  }

  const phone: KudoMaskOptions = {
    mask: '(###) ###-####',
  }

  const zip: KudoMaskOptions = {
    mask: '#####',
  }

  const plusFour: KudoMaskOptions = {
    mask: '####',
  }

  const kudoMasks = {
    date,
    phone,
    zip,
    plusFour,
  }

  return {
    provide: {
      kudoMasks,
    },
  }
})
