export default defineNuxtPlugin((nuxtApp) => {
  const initialLockState = {
    _lockers: {},
    _locked: false,
    get locked() {
      return this._locked
    },
    _isLocked() {
      return Object.keys(this._lockers).some(locker => this._lockers[locker].lockCount > 0)
    },
    lockUntil(fn) {
      if (!fn?.finally) {
        nuxtApp.$bugsnag.notify(new Error('AppLock Plugin: lockUntil() received non-promise.'))
        return
      }

      if (!this._lockers[fn]) {
        this._lockers[fn] = {
          lockCount: 0,
        }
      }

      this._lockers[fn].lockCount++
      this._locked = this._isLocked()

      return fn.finally(() => {
        if (!this._lockers[fn]?.lockCount) {
          this._locked = this._isLocked()
          return
        }

        this._lockers[fn].lockCount--

        if (this._lockers[fn].lockCount < 1) {
          delete this._lockers[fn]
        }

        this._locked = this._isLocked()
      })
    },
  }

  const observableState = reactive(initialLockState)

  return {
    provide: {
      appLock: observableState,
    },
  }
})
