export default defineNuxtPlugin(() => {
  return {
    provide: {
      formatDate: (date, format) => {
        let monthFormat = '2-digit'

        if (format) {
          monthFormat = format === 'shortMonth' ? 'short' : 'long'
        }

        return new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC', year: 'numeric', month: monthFormat, day: '2-digit' })
      },
    },
  }
})
