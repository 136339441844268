<template>
  <!-- TODO: Should this be wrapped in <NuxtLayout name="default"></NuxtLayout>? -->
  <!-- What does prod look like currently? -->
  <div class="max-w-2xl mx-auto mt-16 md:mt-32 text-center">
    <h1 class="text-4xl font-bold text-plum-darkest">
      Oops!
    </h1>
    <h3
      class="text-xl mt-2 text mb-4 text-gray-darkest"
      data-cypress="error-message"
    >
      {{ error.message }}
    </h3>
    <a :href="buttonLink">
      <kudo-button>
        {{ buttonText }}
      </kudo-button>
    </a>
  </div>
</template>

<script>
import { KudoButton } from '@openly-engineering/kudo'

export default {
  name: 'Error',
  components: {
    KudoButton,
  },
  setup() {
    // TODO: This might be how we want this to work, but we should consider moving this data around when we call `createError()`.
    // This could make the error page more flexible.
    const globalError = useError()

    return {
      error: globalError?.value?.data ?? globalError.value,
    }
  },
  computed: {
    buttonLink() {
      return this.error.buttonUrl ? this.error.buttonUrl : `${this.$config.public.legacyPortalDomain}/agents/dashboard`
    },
    buttonText() {
      return this.error.buttonText ? this.error.buttonText : 'Return to Dashboard'
    },
  },
}
</script>
