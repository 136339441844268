import { from } from '@apollo/client/core'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import ErrorHandler from '~/apollo/error-handler'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$apollo.defaultClient.setLink(
    from([
      removeTypenameFromVariables(),
      nuxtApp.$apollo.defaultClient.link,
    ])
  )

  nuxtApp.hook('apollo:error', errors => ErrorHandler(errors, nuxtApp))
})
