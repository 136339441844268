import { saveAs } from 'file-saver'

class DocumentsAPI {
  constructor(tokenGetter, baseURL) {
    const clientOptions = {
      headers: {
        'Cache-Control': 'no-cache',
      },
    }

    if (baseURL) {
      clientOptions.baseURL = baseURL
    }

    this._api = $fetch.create(clientOptions)
    this._tokenGetter = tokenGetter
  }

  async getBearerToken() {
    return await this._tokenGetter()
  }

  async uploadDocument(document, documentType, policyNumber, termID, transactionID) {
    const fd = new FormData()
    fd.append('document', document)
    fd.append('documentType', documentType)

    let url = '/upload'

    if (
      documentType === 'CCAuthorization' ||
      documentType === 'EFTAuthorization'
    ) {
      url = '/secure-upload'
    }

    url = `${url}/policy/${policyNumber}/term/${termID}/transaction/${transactionID}`

    return await this._api(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await this.getBearerToken()}`,
      },
      body: fd,
    })
  }

  async getDocumentBlob(filePath) {
    const fileArrayBuffer = await this._api(`/download/${filePath}`, {
      method: 'GET',
      responseType: 'arrayBuffer',
      headers: {
        Authorization: `Bearer ${await this.getBearerToken()}`,
      },
    })

    return new Blob([fileArrayBuffer])
  }

  async getDocumentFile(filePath, fileName) {
    const documentBlob = await this.getDocumentBlob(filePath)
    return new File([documentBlob], fileName)
  }

  async downloadDocument(filePath, fileName) {
    const file = await this.getDocumentFile(filePath, fileName)
    await saveAs(file)
  }

  async uploadDraftDocument(document, documentType, policyNumber, termID, transactionID) {
    const fd = new FormData()
    fd.append('document', document)
    fd.append('documentType', documentType)

    const url = `/upload-draft-doc/policy/${policyNumber}/term/${termID}/transaction/${transactionID}`

    return await this._api(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await this.getBearerToken()}`,
      },
      body: fd,
    })
  }

  async searchDraftDocuments(policyNumber, termID, transactionID) {
    const url = `/search-draft-docs/policy/${policyNumber}/term/${termID}/transaction/${transactionID}`

    return await this._api(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.getBearerToken()}`,
      },
    })
  }

  async deleteDraftDocument(documentID, policyNumber, termID, transactionID) {
    const url = `/delete-draft-doc/policy/${policyNumber}/term/${termID}/transaction/${transactionID}/draftDocs/${documentID}`

    return await this._api(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${await this.getBearerToken()}`,
      },
    })
  }

  async getDraftDocument(documentID, policyNumber, termID, transactionID) {
    const url = `/get-draft-doc/policy/${policyNumber}/term/${termID}/transaction/${transactionID}/draftDocs/${documentID}`

    const fileArrayBuffer = await this._api(url, {
      method: 'GET',
      responseType: 'arrayBuffer',
      headers: {
        Authorization: `Bearer ${await this.getBearerToken()}`,
      },
    })

    const documentBlob = new Blob([fileArrayBuffer])
    return new File([documentBlob], 'cancellation')
  }
}

export default defineNuxtPlugin(() => {
  const { public: config } = useRuntimeConfig()
  const { getToken } = useApollo()

  return {
    provide: {
      documentsAPI: new DocumentsAPI(() => getToken(), config.documentsEndpoint),
    },
  }
})
