export default defineNuxtPlugin(() => {
  return {
    provide: {
      vuelidateErrorsToStrings: (errors) => {
        if (!errors?.length) {
          return []
        }
        return errors.map((error) => {
          return error.$message
        })
      },
    },
  }
})
