export default defineNuxtRouteMiddleware((to) => {
  if (to.path.includes('file-claim')) {
    const { $store } = useNuxtApp()
    const contactsAndDamageTabsAreValid = !$store.state.claim.contactsTabHasErrors && !$store.state.claim.damageTabHasErrors
    // The user can be "out of bounds" in 2 ways - both require us to send them to the contacts page
    // 1) If contacts aren't populated for the claim flow, this signals empty state due to a page reload; the user must start over
    // 2) If a claim number exists for the claim flow, this signals that the claim has been submitted (and the user is probably navigating via back/forward browser buttons); the user must start over
    const userIsOutOfBounds = $store.state.claim.contacts.length === 0 || $store.state.claim.claimNumber !== ''
    const shouldRouteToContactsTab = (!(to.path.includes('contacts')) && userIsOutOfBounds) || (to.path.includes('review') && !contactsAndDamageTabsAreValid)

    if (shouldRouteToContactsTab) {
      const route = useRoute()
      $store.dispatch('claim/clearApplicationState')
      return navigateTo(`/policy/${route.params.policyNumber}/file-claim/contacts`)
    }
  }
})
