import { WebAuth } from 'auth0-js'

export default defineNuxtPlugin(() => {
  const { public: config } = useRuntimeConfig()

  const auth = new WebAuth({
    domain: config.auth0Domain,
    clientID: config.auth0ClientID,
    redirectUri: `${window.location.origin}/authenticate`,
    audience: config.auth0Audience,
    responseType: 'token id_token',
    scope: 'openid profile email',
  })

  return {
    provide: {
      auth,
    },
  }
})
